import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    userData: any;
    currentUserSubject: any;
    currentUser: any;

    constructor(private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        if(this.currentUserSubject.value == null){
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser1')));
            this.currentUser = this.currentUserSubject.asObservable();
        }
   }

     public get currentUserValue() {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        if(this.currentUserSubject.value == null){
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser1')));
            this.currentUser = this.currentUserSubject.asObservable();
        }

        return this.currentUserSubject.value;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
                
        if (localStorage.getItem('currentUser')) {
            //this.userData = JSON.parse(localStorage.getItem('currentUser')); 
            this.currentUserSubject.next(localStorage.getItem('currentUser'));
            return true;
        }else if (localStorage.getItem('currentUser1')) {
            //.userData = JSON.parse(localStorage.getItem('currentUser1')); 
            this.currentUserSubject.next(localStorage.getItem('currentUser1'));
            return true;
        }else{
             // not logged in so redirect to login page with the return url
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        return false;
        }

    }
}