import { Component, OnInit } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'web';
  message;
  currentUser: any;
  constructor(private messagingService: MessagingService , private router: Router) { }
  ngOnInit() {
    $(document).on('click', '.filter-dropdown .dropdown-menu', function (e) {
      e.stopPropagation();
    });

   // const userId = this.generateUUID();
    this.messagingService.requestPermission()
    this.messagingService.getToken()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage


    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentUser && this.currentUser.status == true) {
    //   this.router.navigate(['/home']);
    // }
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

}
