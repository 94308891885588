export class AppConstants {
  /**
   * Thats Game Api Url
   */

  //public static apiBaseUrl = "https://tgadm.zazz.io/api/";
  public static apiBaseUrl = "https://api.thatsgame.com/api/"
  //public static apiBaseUrl = "http://localhost:3000/api/"


  public static appAuthServiceApi = {
    userLogin: AppConstants.apiBaseUrl + "user/signin",
    userRegistration: AppConstants.apiBaseUrl + "user/signup",
    usersocialRegistration: AppConstants.apiBaseUrl + "user/social-login",
    forgetPassword: AppConstants.apiBaseUrl + "user/reset-password",
    checkUser: AppConstants.apiBaseUrl + "user/verify-user",
    updateFirstLogin: AppConstants.apiBaseUrl + "user/update-Login",
    checksocialUser: AppConstants.apiBaseUrl + "user/verify-social-user",
  };

  public static appReaderServiceApi = {
    /**
     * Get User Profile Data
     */
    getUserData: AppConstants.apiBaseUrl + "user/get-user",
    getgraph: AppConstants.apiBaseUrl + "user/get-graph",
    /**
     * Home Page Api's
     */
    getProtalks: AppConstants.apiBaseUrl + "home/protalks",
    getProvids: AppConstants.apiBaseUrl + "home/provids",
    getCritique: AppConstants.apiBaseUrl + "home/critique",
    getOther: AppConstants.apiBaseUrl + "home/other",
    getVault: AppConstants.apiBaseUrl + "home/vault",

    /**
     * Search Page Api's
     */
    searchProtalks: AppConstants.apiBaseUrl + "search/protalks",
    searchCritique: AppConstants.apiBaseUrl + "search/critique",
    searchOther: AppConstants.apiBaseUrl + "search/other",
    searchUser: AppConstants.apiBaseUrl + "search/users",
    searchProvids: AppConstants.apiBaseUrl + "search/provids",
    /**
     * Video Details Page Api
     */
    getVideoDetails: AppConstants.apiBaseUrl + "media/media-info",
    getcommentSuggestion: AppConstants.apiBaseUrl + "media/get-suggest-word",
    getFavouriteMedia: AppConstants.apiBaseUrl + "media/get-favorites",
    getStatsMedia: AppConstants.apiBaseUrl + "media/statat",
    getStrategy: AppConstants.apiBaseUrl + "media/get-strategy",
    getImageGallery: AppConstants.apiBaseUrl + "media/gallery-thumbs",

    /**
     * Dashboard/User Details Page Api
     */
    getDashboardContent: AppConstants.apiBaseUrl + "user/get-user",
    getFollowerList: AppConstants.apiBaseUrl + "user/get-follower",
    getStatsMediaData: AppConstants.apiBaseUrl + "user/get-statatics",
    getUserMedia: AppConstants.apiBaseUrl + "user/get-user-media",
    /**
     * Content vault Api's
     */
    getContentVault: AppConstants.apiBaseUrl + "home/vault",

    /**
     * Notifications Api
     */
    getNotifications: AppConstants.apiBaseUrl + "notification/get-notification",
    countNotification:
      AppConstants.apiBaseUrl + "notification/notification-count",

    /**
     * Support/Feedback/Faq Api
     */
    getFaq: AppConstants.apiBaseUrl + "user/get-faq",

    /**
     * Get Comment on media
     */
    getCommentOnMedia: AppConstants.apiBaseUrl + "media/get-comment",
  };

  public static appWriterServiceApi = {
    /**
     * Save Cooment on Media
     */
    saveCommentOnMedia: AppConstants.apiBaseUrl + "media/comment-save",
    actionOnMedia: AppConstants.apiBaseUrl + "media/statat",
    savevideoAudioData: AppConstants.apiBaseUrl + "user/videos-save",
    postfeedback: AppConstants.apiBaseUrl + "user/save-feedback",
    followuser: AppConstants.apiBaseUrl + "user/follow",
    reportMedia: AppConstants.apiBaseUrl + "media/report",
    randomMedia: AppConstants.apiBaseUrl + "media/random-media",
    posttoVault: AppConstants.apiBaseUrl + "media/mdedia-vault",
    /**
     * Upload Pic
     */
    uplademedia: AppConstants.apiBaseUrl + "upload/file",
    uploadPic: AppConstants.apiBaseUrl + "upload/image",
    uploadTrimVideo: AppConstants.apiBaseUrl + "upload/trim-video",
    uploadTempFile:AppConstants.apiBaseUrl + "upload/temp-file",
    uploadAudioComment: AppConstants.apiBaseUrl + "upload/audio",
    /**
     * Send Mail to Admin For Pro Mentor
     */
    sendMailtoAdmin: AppConstants.apiBaseUrl + "user/mentor-req",
    changePassword: AppConstants.apiBaseUrl + "user/change-password",
    /**
     * Delete Content
     */
    removeMedia: AppConstants.apiBaseUrl + "media/remove-media",
    deletecomment: AppConstants.apiBaseUrl + "media/del-comment",
    publish2Content: AppConstants.apiBaseUrl + "media/mdedia-publish",
    /**
     * Update Notification
     */
    updateNotification:
      AppConstants.apiBaseUrl + "notification/update-notification-status",
    updaeUser: AppConstants.apiBaseUrl + "user/update-profile",
    updatemail: AppConstants.apiBaseUrl + "user/update-mentor-info",

    /**
     * Subcription
     */

    subscription: AppConstants.apiBaseUrl + "payment/checkout",
    savesubscription: AppConstants.apiBaseUrl + "payment/saveSubscriptionPlan",
    verifytwitter: AppConstants.apiBaseUrl + "payment/verifyCredentials",
    unsubscribe: AppConstants.apiBaseUrl + "payment/cancelSubscriptionPlan",
  };

  public static redirectPageUrl = {};

  public static responseCode = {
    SUCCESS: 200,
    RECORD_CREATED: 201,
    REQUEST_ACCEPTED: 202,
    NO_CONTENT: 204,
    PARTIAL_CONTENT: 206,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
  };
}
