import {Inject, Injectable} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {AppConstants} from '../../shared/common/app.constants';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class LoginService {
  constructor(
    @Inject(ApiService) protected apiService: ApiService,
    private http:HttpClient
  ) {
  }

  userLogin(loginData) {
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.userLogin}`, loginData);
  }

  userSignUp(loginData) {
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.userRegistration}`, loginData);
  }

  usersocialSignUp(loginData) {
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.usersocialRegistration}`, loginData);
  }

  userUploadPic(loginData) {
    return this.apiService.executePostMethod(`${AppConstants.appWriterServiceApi.uploadPic}`, loginData);
  }

  sendMailtoAdmin(data) {
    return this.apiService.executePostMethod(`${AppConstants.appWriterServiceApi.sendMailtoAdmin}`, data);
  }

  verifyUser(data) {
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.checkUser}`, data);
  }

  checksocialUser(data){
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.checksocialUser}`, data);
  }


  forgetPassword(forgetdata){
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.forgetPassword}`, forgetdata);
  }

  update(updatedata){
    return this.apiService.executePatchMethod(`${AppConstants.appWriterServiceApi.updaeUser}`,updatedata);
  }

  getDashboardContent(params) {
    return this.apiService.executeGetMethod(`${AppConstants.appReaderServiceApi.getDashboardContent}` + '?userId='+params);
  }

  getnotificationCount(params){
    return this.apiService.executePostMethod(`${AppConstants.appReaderServiceApi.countNotification}` , params);
  }

  getTwitterData(params){
    return this.apiService.executePostMethod(`${AppConstants.appWriterServiceApi.verifytwitter}` , params);
  }

  updatementorFirstLogin(params){
    return this.apiService.executePostMethod(`${AppConstants.appAuthServiceApi.updateFirstLogin}` , params);
  }

  logoutfbuser(token){
    return this.http.get('https://www.facebook.com/x/oauth/logout?access_token='+token.token)
  }
}
