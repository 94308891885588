import { Injectable } from '@angular/core';
//import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthGuard } from 'src/app/shared/services/auth.gaurd';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthGuard,private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
         //console.log(typeof currentUser,"====================>",currentUser)
        // console.log("====================>", request)
        if (currentUser != null && currentUser.hasOwnProperty("result")) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done"),
                    "x-access-token": currentUser.result.token
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    //Authorization: `Bearer ${currentUser.token}`,
                    Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done")
                }
            });

        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
               // console.log("error",error.error.errorcode ===)

               if(error.error.errorcode == 404){
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentUser1');
                this.router.navigate(['/'])
                return throwError(error);
              }
            })
        )

    }
}