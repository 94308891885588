import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                localStorage.setItem('ftoken', JSON.stringify({ ftoken: token}))
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    getToken(){

        this.angularFireMessaging.getToken.subscribe(
            (token) => {
                console.log(token);
                localStorage.setItem('ftoken', JSON.stringify({ ftoken: token}))
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );

    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            })
    }
}