import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "src/app/shared/services/auth.gaurd";

var routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: "about",
    loadChildren: () =>
      import("./modules/about/about.module").then((m) => m.AboutModule),
  },
  {
    path: "about/:type",
    loadChildren: () =>
      import("./modules/about/about.module").then((m) => m.AboutModule),
  },
  {
    path: "create-profile",
    loadChildren: () =>
      import("./modules/create-profile/create-profile.module").then(
        (m) => m.CreateProfileModule
      ),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./modules/sign-up/sign-up.module").then((m) => m.SignUpModule),
  },
  {
    path: "subscription/:point",
    loadChildren: () =>
      import("./modules/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./modules/terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "provids",
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "search",
    loadChildren: () =>
      import("./modules/search/search.module").then((m) => m.SearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: "video/:id",
    loadChildren: () =>
      import("./modules/video/video.module").then((m) => m.VideoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "vault-video/:id",
    loadChildren: () =>
      import("./modules/video/video.module").then((m) => m.VideoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "post",
    loadChildren: () =>
      import("./modules/post/post.module").then((m) => m.PostModule),
    canActivate: [AuthGuard],
  },
  {
    path: "post-media/:type",
    loadChildren: () =>
      import("./modules/post-media/post-media.module").then(
        (m) => m.PostMediaModule
      ),
    canActivate: [AuthGuard],
  },
  //{ path: 'post-media-publish', loadChildren: () => import('./modules/post-media-publish/post-media-publish.module').then(m => m.PostMediaPublishModule),canActivate:[AuthGuard] },
  {
    path: "post-vault-publish/:id",
    loadChildren: () =>
      import("./modules/post-vault-publish/post-vault-publish.module").then(
        (m) => m.PostVaultPublishModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "content-vault",
    loadChildren: () =>
      import("./modules/content-vault/content-vault.module").then(
        (m) => m.ContentVaultModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./modules/notification/notification.module").then(
        (m) => m.NotificationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "support",
    loadChildren: () =>
      import("./modules/support/support.module").then((m) => m.SupportModule),
    canActivate: [AuthGuard],
  },
  {
    path: "my-account/:id",
    loadChildren: () =>
      import("./modules/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile-youth",
    loadChildren: () =>
      import("./modules/profile-youth/profile-youth.module").then(
        (m) => m.ProfileYouthModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile-youth/:userId",
    loadChildren: () =>
      import("./modules/profile-youth/profile-youth.module").then(
        (m) => m.ProfileYouthModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile-youth-personal",
    loadChildren: () =>
      import("./modules/profile-youth/profile-youth.module").then(
        (m) => m.ProfileYouthModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile-mentor",
    loadChildren: () =>
      import("./modules/profile-mentor/profile-mentor.module").then(
        (m) => m.ProfileMentorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile-mentor/:userId",
    loadChildren: () =>
      import("./modules/profile-mentor/profile-mentor.module").then(
        (m) => m.ProfileMentorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "provids-search",
    loadChildren: () =>
      import("./modules/provids-search/provids-search.module").then(
        (m) => m.ProvidsSearchModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./modules/privacy/privacy.module").then((m) => m.PrivacyModule),
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./modules/privacy/privacy.module").then((m) => m.PrivacyModule),
  },
  {
    path: "terms-condition",
    loadChildren: () =>
      import("./modules/terms-condition/terms-condition.module").then(
        (m) => m.TermsConditionModule
      ),
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("./modules/terms-condition/terms-condition.module").then(
        (m) => m.TermsConditionModule
      ),
  },
  {
    path: "payment-gateway",
    loadChildren: () =>
      import("./modules/payment-gateway/payment-gateway.module").then(
        (m) => m.PaymentGatewayModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
