import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class SessionService {

    constructor(private cookieService: CookieService) {
    }

  // tslint:disable-next-line:only-arrow-functions
    getItem = function(itemName) {
        return JSON.parse(localStorage.getItem(itemName));
    };

  // tslint:disable-next-line:only-arrow-functions
    addItem = function(itemName, value) {
        localStorage.setItem(itemName, JSON.stringify(value));
    };

  // tslint:disable-next-line:only-arrow-functions
    removeItem = function(itemName) {
        localStorage.removeItem(itemName);
    };

  // tslint:disable-next-line:only-arrow-functions
    clearAll = function() {
        localStorage.clear();
    };

}
