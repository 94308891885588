import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HelperService} from '../helper/helper.service';

@Injectable()
export class ApiService {
    constructor(
      @Inject(HttpClient) protected httpClient: HttpClient,
      @Inject(HelperService) protected helperService: HelperService
    ) {}

    executeGetMethod(method) {
      
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
        })
      };
        return this.httpClient.get<any>(method,httpOptions)
            .map(response => response)
            .catch(this.helperService.handleError);
    }

    executeDeleteMethod(method , params) {
      
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
        }),
        body: {
          commentId:params.commentId,
        },
      };
        return this.httpClient.delete<any>(method,httpOptions)
            .map(response => response)
            .catch(this.helperService.handleError);
    }

    executeImgGetMethod(method) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
        })
      };
      return this.httpClient.get<any>(method,httpOptions)
          .map(response => response)
          .catch(this.helperService.handleError);
  }

    executePostMethod(method, params?) {
        const requestParams: any = JSON.stringify(params);
        const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
        })
      };
         return this.httpClient.post<any>(method, requestParams, httpOptions)
            .map(response => response)
            .catch(this.helperService.handleError);
    }

    executeMediaPostMethod(method, params?) {
      const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
      })
    };
      return this.httpClient.post<any>(method, params, httpOptions)
          .map(response => response)
          .catch(this.helperService.handleError);
  }

    

    executePatchMethod(method, params?) {
      const requestParams: any = JSON.stringify(params);
      const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
      })
    };
      return this.httpClient.patch<any>(method, requestParams, httpOptions)
          .map(response => response)
          .catch(this.helperService.handleError);
  }



    executeImgPostMethod(method, params?) {
      const requestParams: any = JSON.stringify(params);
      const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Basic ' + btoa('3xaUser!@3#:9raPass@3!)#@done')
      })
    };
      return this.httpClient.post<any>(method, requestParams, httpOptions)
          .map(response => response)
          .catch(this.helperService.handleError);
  }
}
