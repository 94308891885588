import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class HelperService {

    constructor() {
    }

    isUndefinedOrNull = function (obj) {
        return ((typeof obj === 'undefined') || obj === null || obj === '');
    };

    isBlank = function (str) {
        return (!str || 0 === str.length);
    };

    handleError = function (err: HttpErrorResponse | any) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `ERROR: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //errorMessage = `Server returned code: ${err.status}, error message is: ${err.error.msg}`;
            //errorMessage = `ERROR: ${err.error.msg}`;
            errorMessage = `ERROR: ${err.error}`;
        }
        return Observable.throw(errorMessage);
    }

    calculate_age(dob) {
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
}
