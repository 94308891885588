// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseInnerpageImgUrl: '../../../assets/images',
  BaseApiUrl: "localhost:3000/api",
  siteUrl: "http://www.thatsgame.com",  
  firebase: {
    apiKey: "AIzaSyBO89SFZZ0HQnvbKa0PMJLX_x4jfBXM4Z4",
    authDomain: "thats-game-4ec52.firebaseapp.com",
    databaseURL: "https://thats-game-4ec52.firebaseio.com",
    projectId: "thats-game-4ec52",
    storageBucket: "thats-game-4ec52.appspot.com",
    messagingSenderId: "421392248600",
    appId: "1:421392248600:web:318f73ed09dcdbee7cb86d",
    measurementId: "G-RSSJ6056J4"
  },
  
  planId : 'plan_H3Li9ZhoC6iBHC',


  //Live Key
  // planId : 'price_1HD709Hc9XqnyqzM1h0v5s1c',

  // firebase : {
  //   apiKey: "AIzaSyB-CkU1i1xVX9ZMNdc2F2q2k41RI4--ZoE",
  //   authDomain: "thatsgame-283905.firebaseapp.com",
  //   databaseURL: "https://thatsgame-283905.firebaseio.com",
  //   projectId: "thatsgame-283905",
  //   storageBucket: "thatsgame-283905.appspot.com",
  //   messagingSenderId: "926532470755",
  //   appId: "1:926532470755:web:344519ccd4e1dcb59bc2d6",
  //   measurementId: "G-G7BT31WMR3"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
